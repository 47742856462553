import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import Link from '@components/ui/Link'
import cn from 'classnames'

interface TagFiltersProps {
  items: {
    label: string
    link: string
  }[]
  overflow: 'wrap' | 'scroll'
  alignment: 'center' | 'left'
}

const TagFilters: FC<TagFiltersProps> = ({ items, overflow, alignment = 'left' }) => {
  const filtersRef = useRef<HTMLDivElement>(null)

  const updateFiltersPosition = useCallback(() => {
    if (filtersRef.current && alignment === 'center') {
      if (filtersRef.current.scrollWidth > filtersRef.current.offsetWidth) {
        filtersRef.current.classList.remove('justify-center')
      } else {
        filtersRef.current.classList.add('justify-center')
      }
    }
  }, [filtersRef, alignment])

  useEffect(() => {
    window.addEventListener('resize', updateFiltersPosition)
    updateFiltersPosition()
    return () => {
      window.removeEventListener('resize', updateFiltersPosition)
    }
  }, [updateFiltersPosition])

  return (
    <div
      ref={filtersRef}
      className={cn('flex', 'laptop:pl-0', {
        'overflow-x-scroll laptop:overflow-x-hidden': overflow === 'scroll',
        'justify-center': alignment === 'center',
        'flex-wrap': overflow === 'wrap',
      })}
    >
      {items?.map((item: any, index: number) => (
        <Fragment key={`${index}_${item?.label}`}>
          {item?.label && (
            <Link href={item?.link || '/'}>
              <button
                className={cn(
                  'title-small whitespace-nowrap px-16 py-8 border hover:border-black rounded-full border-doveGrey uppercase tracking-wider',
                  { 'mr-8': index !== items.length - 1, 'mb-8': overflow === 'wrap' }
                )}
              >
                {item?.label}
              </button>
            </Link>
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default TagFilters
