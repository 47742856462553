import TagFilters from '@components/common/TagFilters'
import { FC } from 'react'
import cn from 'classnames'
interface props {
  primary: {
    tag_filters_heading: string
    top_padding: boolean
    alignment?: 'center' | 'left'
  }
  items: {
    label: string
    link: string
  }[]
}

const GoToFilters: FC<props> = ({ primary, items }) => {
  return (
    <div
      className={`mb-40 laptop:mb-52 ${primary?.top_padding == true ? 'mt-40 laptop:mt-80' : ''} ${
        primary?.alignment == 'center' ? 'text-center' : ''
      }`}
    >
      <div className={cn('wrapper')}>
        {primary?.tag_filters_heading && <h2 className="heading-5 text-black mb-24">{primary.tag_filters_heading}</h2>}
        <TagFilters items={items} overflow="scroll" alignment={primary?.alignment || 'left'} />
      </div>
    </div>
  )
}

export default GoToFilters
